/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import striptags from 'striptags'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import {FormattedMessage, injectIntl} from 'react-intl';
import { Flex, Box } from '../../common/Grid'
import Container from '../../common/Container'
import Divider from '../../common/Divider'
import Image from '../../common/Image'
import HomeSolutionsPic from '../../../assets/images/royale-front-hs-en.webp'
import HomeSolutionsPicFr from '../../../assets/images/royale-front-hs-fr.jpg'


import {
  TextBlock,
  LinkBlock,
  HeaderBlock,
  ImageBlock
} from '..'

const PantheraStyles = css`
  max-width: 300px;
  margin-top: -75px;
  margin-left: auto;
`

const HomepageTipsStyles = css`
  max-width: 450px;
  margin: 0 auto;

  @media screen and (min-width: 40em) {
    margin: 0px 0px 20px;
  }

  @media screen and (max-width: 64em) {
    max-width: 100%;
  }
`

const AboutUsVideoStyles = css`
  ${tw`items-center`}

  p.p1 {
    ${tw`pl-10`}
  }

  a {
    ${tw`ml-10`}
  }

  @media screen and (max-width: 64em) {
    p {
      ${tw`pl-0`}
    }
  }
`

const ImageLeft = styled(Image)`
  width: 100%;
  ${props => (props.modification.includes('panthera')) ? PantheraStyles : null}
  ${props => (props.modification.includes('homepagetips')) ? HomepageTipsStyles : null}
  ${props => (props.modification.includes('img-width-375')) ? 'width: 375px; margin-left: 60px;' : 'margin-right: 40px;'}

  @media screen and (max-width: 991px) {
    ${props => (props.modification.includes('img-width-375')) ? 'width: auto;margin-left: 0;' : 'margin-right: 0;'}
    width: 100%;
  }
`

const ImageLeftTextRightWrapper = styled.div`
  ${tw`mb-20`}
  ${props => (props.modification.includes('biancablue')) ? tw`bg-light-blue pt-24 pb-24` : tw`bg-none`}

  :lang(zh-hans) h2,
  :lang(zh-hant) h2 {
    font-family: "Montserrat", "DejaVu Sans", Verdana, sans-serif;
    font-weight: 400;
  }
`

const ImageLeftTextRightFlex = styled(Flex)`
  ${props => (props.modification.includes('aboutusvideo')) ? AboutUsVideoStyles : null}
  ${tw`flex-wrap`}
`

const FixedContainer = styled(Container)`
  overflow: hidden;
`

const ImageLeftTextRightBlock = (props) => {
  const { location, modification, alt, image, right, grid, className, loading, intl } = props;

  let SidePadding = 2;
  if (modification.includes('wider')) {
    SidePadding = 5;
  }

  let alignItems = 'stretch';
  if (modification.includes('center')) {
    alignItems = 'center';
  }

  let leftGrid = [1,1/2,1/2,1/2];
  let rightGrid = [1,1/2,1/2,1/2];
  if (grid === 'onethird') {
    leftGrid = [1,1/3,1/3,1/3];
    rightGrid = [1,2/3,2/3,2/3];
  }

  if (grid === 'twothird') {
    leftGrid = [1,2/3,2/3,2/3];
    rightGrid = [1,1/3,1/3,1/3];
  }

  return (
    <ImageLeftTextRightWrapper modification={modification} className={className}>
      <FixedContainer>
        <ImageLeftTextRightFlex
          modification={modification}
          alignItems={alignItems}
          flexWrap="wrap"
          flexDirection={['column', 'row', 'row', 'row']}
          mx={[
            0,
            -2,
            -2,
            SidePadding * -1
          ]}
        >
          <Box
            width={leftGrid}
            px={[
              0,
              2,
              2,
              SidePadding
            ]}
            mb={[
              5,
              0,
              0,
              0
            ]}
            className="image"
          >
            { image ?
              (
                location === "home" ?
                  <img src={intl.locale === 'fr' ? HomeSolutionsPicFr : HomeSolutionsPic} alt="Home Solutions" />
                  :
                  <ImageLeft
                    url={image}
                    alt={striptags(alt)}
                    sizes="(min-width: 64em) 620px, 100vw"
                    modification={modification}
                    loading={loading}
                  />
            )
            : null }
          </Box>

          <Box
            width={rightGrid}
            px={[
              0,
              2,
              2,
              SidePadding
            ]}
          >

            {right.map((block) => {
              // eslint-disable-next-line dot-notation
              switch (block['__typename']) {
                case 'DividerBlock':
                  return <Divider color={block.color} key={block.guid} />
                case 'TextBlock':
                  return <TextBlock modification={block.modification} text={block.text} key={block.guid} />
                case 'LinkBlock':
                  return <LinkBlock backgroundColor={block.backgroundColor} modification={block.modification} title={block.title} link={block.link} key={block.guid} snapchat={block.snapchat} gtag={block.gtag} ga={block.ga} appnexus={block.appnexus} />
                case 'HeaderBlock':
                  return <HeaderBlock modification={block.modification} title={block.title} type={block.type} key={block.guid} />
                case 'ImageBlock':
                  return <ImageBlock modification={block.modification} image={block.image_url} key={block.guid} inside />
                default:
                  // eslint-disable-next-line dot-notation
                  console.log(`BLOCK ${block['__typename']} NOT DEFINED`);
              }

              return null
            })}
          </Box>
        </ImageLeftTextRightFlex>
      </FixedContainer>
    </ImageLeftTextRightWrapper>
  )
}

ImageLeftTextRightBlock.propTypes = {
  modification: PropTypes.string,
  image: PropTypes.oneOfType([
    PropTypes.shape({
      base64: PropTypes.string,
      width: PropTypes.string,
      height: PropTypes.string,
      src: PropTypes.string,
      srcSet: PropTypes.string
    }),
    PropTypes.string
  ]).isRequired,
  alt: PropTypes.string.isRequired,
  right: PropTypes.arrayOf([
    PropTypes.object
  ]).isRequired,
  grid: PropTypes.string,
  className: PropTypes.string,
  loading: PropTypes.string
}

ImageLeftTextRightBlock.defaultProps = {
  modification: '',
  grid: 'half',
  className: '',
  loading: 'lazy'
}

export const imageLeftTextRightBlockFragment = graphql`
  fragment imageLeftTextRightBlockFragment on ImageLeftTextRightBlock {
    id
    modification
    image_url
    right {
      __typename

      ... on TextBlock {
        guid
        modification
        text
      }

      ... on LinkBlock {
        guid
        title
        modification
        link
      }

      ... on HeaderBlock {
        guid
        modification
        type
        title
      }

      ... on ImageBlock {
        guid
        modification
        image_url
      }
    }
  }
`

export default injectIntl(ImageLeftTextRightBlock)
